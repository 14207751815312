import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import PropTypes from 'prop-types'
import {
  assoc,
  compose,
  equals,
  find,
  isNil,
  nthArg,
  prop,
  propEq,
  replace,
  reverse,
  toUpper
} from 'ramda'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { subdomainsFromUserDomains } from 'utils/helpers'

const Category = ({ goal, isEdit, setGoal }) => {
  const { userDomains } = useSelector(prop('user'))
  const [inputValue, setInputValue] = useState('')
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  const subdomains = subdomainsFromUserDomains(userDomains)

  const newMapped = subdomains.map(({ section_id, ...rest }) => {
    const domain = find(propEq(section_id, 'id'), userDomains)
    // return new domain name
    // capitalize
    // this is so we can group options
    return {
      domain: replace(/(^| )(\w)/g, toUpper(), prop('subtitle', domain)),
      section_id,
      ...rest
    }
  })

  const handleChange = ({ id }) => setGoal(assoc('subdomain_id', id, goal))

  const value = useMemo(() => {
    if (isNil(prop('subdomain_id', goal))) return null
    const found = newMapped.find(({ id }) =>
      equals(id, prop('subdomain_id', goal))
    )
    return found
  }, [goal, newMapped])

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
      sx={{ justifyContent: 'center', width: 1 }}
    >
      <Typography
        sx={
          isMobile
            ? {
                pl: 1,
                pt: 2,
                whiteSpace: 'nowrap'
              }
            : {
                pt: 2,
                whiteSpace: 'nowrap'
              }
        }
      >
        Category*
      </Typography>
      <Autocomplete
        disabled={isEdit}
        fullWidth
        getOptionLabel={prop('title')}
        groupBy={prop('domain')}
        inputValue={inputValue}
        onChange={compose(handleChange, nthArg(1))}
        onInputChange={compose(setInputValue, nthArg(1))}
        options={reverse(newMapped)}
        renderGroup={params => (
          <Box key={prop('key', params)}>
            <Typography
              component="span"
              sx={{
                lineHeight: 1.71,
                pl: 2,
                py: 2
              }}
              variant="h6"
            >
              {prop('group', params)}
            </Typography>
            <Stack
              component="ul"
              sx={{
                pl: 3
              }}
            >
              {prop('children', params)}
            </Stack>
          </Box>
        )}
        renderInput={params => (
          <TextField {...params} placeholder="Give your goal a focus" />
        )}
        sx={{
          maxWidth: 460
        }}
        value={value}
      />
    </Stack>
  )
}

Category.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  isEdit: PropTypes.bool.isRequired,
  setGoal: PropTypes.func.isRequired
}

export default Category

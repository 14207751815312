/* eslint-disable sort-keys */
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@mui/material'

const AccessibleModal = ({
  children,
  fullScreenMobile = false,
  onClose,
  open = false,
  title = null
}) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))
  return (
    <Dialog
      aria-labelledby="dialog-title"
      fullScreen={isMobile && fullScreenMobile}
      onClose={onClose}
      open={open}
      role="alertdialog"
    >
      <DialogTitle
        component="h1"
        id="dialog-title"
        sx={{ mt: 2, mx: 'auto' }}
        tabIndex={-1}
      >
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          pb: { mobile: 3, laptop: 4.5 },
          px: { mobile: 2, laptop: 4 }
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

AccessibleModal.propTypes = {
  children: PropTypes.any.isRequired,
  fullScreenMobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

export default AccessibleModal

import PropTypes from 'prop-types'
import { compose, assoc, path, prop } from 'ramda'
import { Stack, TextField, Typography, useMediaQuery } from '@mui/material'

const Name = ({ goal, setGoal, setIsChanged }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))
  const handleChange = e => {
    compose(setGoal, assoc('title'), path(['target', 'value']))(e)
    setIsChanged(true)
  }

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
      sx={{ justifyContent: 'center', width: 1 }}
    >
      <Typography
        sx={
          isMobile
            ? {
                pl: 1,
                pt: 2,
                whiteSpace: 'nowrap'
              }
            : {
                pt: 2,
                whiteSpace: 'nowrap'
              }
        }
      >
        Goal Name*
      </Typography>
      <TextField
        fullWidth
        id="goal-name"
        // update goal title on change
        // get value from e.target.value
        // associate value to goal title in goal object
        // set goal
        onChange={handleChange}
        placeholder="What do you want to accomplish?"
        required
        sx={{ maxWidth: 460 }}
        value={prop('title', goal)}
      />
    </Stack>
  )
}

Name.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired,
  setIsChanged: PropTypes.func.isRequired
}

export default Name

import PropTypes from 'prop-types'
import { assoc, is, prop } from 'ramda'
import { DatePicker } from '@mui/x-date-pickers'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import Icon from 'components/icon/Icon'

const DueDate = ({ goal, isCustom = false, setGoal, setIsChanged }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
      sx={{ justifyContent: 'center', width: 1 }}
    >
      {isCustom && (
        <Typography
          sx={
            isMobile
              ? {
                  pl: 1,
                  pt: 2,
                  whiteSpace: 'nowrap'
                }
              : {
                  pt: 2,
                  whiteSpace: 'nowrap'
                }
          }
        >
          Due Date
        </Typography>
      )}
      <Stack
        spacing={0.5}
        sx={
          isCustom
            ? { maxWidth: 460, pr: -5, width: 1 }
            : { maxWidth: 460, width: 1 }
        }
      >
        <DatePicker
          minDate={new Date()}
          onChange={date => {
            setGoal(assoc('due_date', date))
            setIsChanged(true)
          }}
          slotProps={{ field: { clearable: true } }}
          sx={{
            width: 1
          }}
          value={
            is(String, prop('due_date', goal))
              ? new Date(prop('due_date', goal))
              : prop('due_date', goal)
          }
        />
        {isCustom && (
          <Stack alignItems="center" direction="row" spacing={0.5}>
            <Icon
              fill
              sx={{
                fontSize: '16px !important'
              }}
            >
              info
            </Icon>
            <Typography variant="body2">
              Deadlines are helpful for some people but not all.
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

DueDate.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  isCustom: PropTypes.bool,
  setGoal: PropTypes.func.isRequired,
  setIsChanged: PropTypes.func.isRequired
}

export default DueDate

/* eslint-disable sort-keys */
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Badge, Box, Stack, Tab, Typography } from '@mui/material'
import { equals, flip, isNil, length } from 'ramda'
import { useGetGoalsQuery } from 'api/goalsApi'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

import Explore from './components/Explore'
import InProgress from './components/InProgress'
import Completed from './components/Completed'

const Goals = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const active = search.get('active')

  const { data: inProgress } = useGetGoalsQuery()

  const handleRouteChange = active => navigate(`/goals?active=${active}`)

  useEffect(() => {
    if (isNil(active)) navigate('/goals?active=explore')
  }, [active, navigate])

  return (
    <Stack spacing={4}>
      <Box
        sx={{
          backgroundImage: 'url(/vectors/goals-banner.svg)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 383,
          width: 1
        }}
      />
      <Stack
        alignItems="center"
        spacing={{
          mobile: 2,
          // eslint-disable-next-line sort-keys
          tablet: 3
        }}
        sx={{ mb: 2 }}
      >
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Goals" id="XHLYdJ" />
        </Typography>
        <Typography
          color="secondary.dark"
          px={{ mobile: 2 }}
          sx={{ maxWidth: 497 }}
          textAlign="center"
        >
          <FormattedMessage
            defaultMessage="Writing a goal down makes you 5x more likely to achieve it. This is your space to create your own or explore 100+ ready made ideas."
            id="8KUduv"
          />
        </Typography>
      </Stack>
      <TabContext value={active}>
        <Stack
          aria-label="goal tabs"
          component={TabList}
          direction="row"
          onChange={flip(handleRouteChange)}
          spacing={1}
          sx={{
            alignItems: 'center',
            borderBottom: 1,
            borderColor: 'surface.highest',
            justifyContent: 'center',
            pb: 4,
            width: 1
          }}
        >
          <Tab label="Explore Goals" value="explore" variant="tab" />
          <Tab
            iconPosition="end"
            label={
              <Stack>
                {equals('explore', active) && (
                  <Badge
                    badgeContent={length(inProgress)}
                    overlap="circular"
                    sx={{
                      alignSelf: 'flex-end',
                      right: -4
                    }}
                    variant="dot"
                  />
                )}
                <Typography
                  sx={{
                    color: equals('my-goals', active)
                      ? '#211F1C'
                      : 'surface.onSurface'
                  }}
                >
                  My Goals
                </Typography>
              </Stack>
            }
            value="my-goals"
            variant="tab"
          />
        </Stack>
        <TabPanel sx={{ pt: 0, px: 0 }} value="explore">
          <Explore />
        </TabPanel>
        <TabPanel
          sx={{ px: { mobile: 2, tablet: 3, laptop: 5 } }}
          value="my-goals"
        >
          <Stack>
            <InProgress />
            <Completed />
          </Stack>
        </TabPanel>
      </TabContext>
    </Stack>
  )
}

export default Goals

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  all,
  always,
  assoc,
  equals,
  evolve,
  isEmpty,
  isNil,
  map,
  not,
  or,
  path,
  prop,
  reject
} from 'ramda'
import {
  resetState,
  setImg,
  setIsConfirmationOpen,
  setIsPresetGoalModalOpen,
  setPresetGoalModalId
} from '../goalSlice'
import { useGetCardQuery } from 'api/cardsApi'
import { useEffect, useState } from 'react'
import { useCreateGoalMutation } from 'api/goalsApi'
import AccessibleModal from 'components/modals/AccessibleModal'
import DueDate from './DueDate'

const PresetGoalModal = () => {
  const dispatch = useDispatch()
  const { presetGoalModalId, isPresetGoalModalOpen } = useSelector(prop('goal'))
  const cloudFront = useSelector(path(['school', 'cloudfront_domain']))
  const [consent, setConsent] = useState(false)
  const blankGoal = {
    campus_goal_id: null,
    card_id: null,
    domain_id: null,
    due_date: null,
    steps: [
      {
        completed: false,
        id: null,
        name: ''
      }
    ],
    subdomain_id: null,
    title: ''
  }
  // Updating Goal
  const [goal, setGoal] = useState(blankGoal)

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  const handleClose = () => {
    setIsPresetGoalModalOpen(false)
    setPresetGoalModalId(null)
    setGoal(blankGoal)
    dispatch(resetState())
  }

  const {
    data = {},
    isFetching = true,
    isSuccess = false
  } = useGetCardQuery(presetGoalModalId, { skip: isNil(presetGoalModalId) })

  const [createGoal, { isLoading = false }] = useCreateGoalMutation()

  useEffect(() => {
    if (
      all(equals(true))([not(isEmpty(data)), isSuccess, isPresetGoalModalOpen])
    ) {
      // Since res from campus goals is not correct shape
      // map steps to be correct shape
      // update the goal to the new found data
      const updatedSteps = map(
        (str, idx) => ({
          completed: false,
          id: idx,
          name: str
        }),
        path(['campus_goal', 'steps'], data)
      )
      setGoal(
        evolve(
          {
            campus_goal_id: always(path(['campus_goal', 'id'], data)),
            card_id: always(parseInt(presetGoalModalId)),
            domain_id: always(prop('section_id', data)),
            due_date: always(null),
            steps: always(updatedSteps),
            subdomain_id: always(path(['campus_goal', 'subdomain_id'], data)),
            title: always(prop('title', data))
          },
          goal
        )
      )
    }
  }, [isPresetGoalModalOpen])

  const handleSubmit = async () => {
    try {
      const body = new FormData()
      const transformedGoal = reject(
        isNil,
        assoc(
          'due_date',
          prop('due_date', goal) ? prop('due_date', goal).toISOString() : '',
          goal
        )
      )
      transformedGoal.notified_on_due_date = consent
      for (const [key, value] of Object.entries(transformedGoal)) {
        if (equals(key, 'steps')) {
          goal.steps.forEach((step, index) => {
            body.append('steps[' + index + '][name]', step.name)
          })
        } else {
          body.append(key, value)
        }
      }
      const goalResponse = await createGoal(body)
      dispatch(
        setImg(
          `https://${cloudFront}/${path(['campus_goal', 'card_image'], data)}`
        )
      )
      // Set goal id in local storage
      localStorage.setItem(
        'confirmation_modal_goal_id',
        prop('id', prop('data', goalResponse))
      )
      setTimeout(() => {
        dispatch(setIsConfirmationOpen(true))
      })
      // set goal title to null to reset the modal
      setGoal(
        evolve(
          {
            title: always(null)
          },
          goal
        )
      )
    } catch (err) {
      console.warn('Error creating a premade goal:', err)
    }
  }

  return (
    <AccessibleModal
      fullScreenMobile={true}
      onClose={handleClose}
      open={isPresetGoalModalOpen}
      title={
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            maxWidth: 260
          }}
        >
          <Box
            sx={{
              backgroundImage: 'url(/vectors/you-logo.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 24,
              width: 26
            }}
          />
          {not(isFetching) ? (
            <Typography
              sx={{
                fontWeight: 700,
                textAlign: 'center',
                textTransform: 'none'
              }}
              variant="h4"
            >
              {prop('title', data)}
            </Typography>
          ) : (
            <Skeleton height={36} width={240} />
          )}
          <Typography
            sx={{
              fontWeight: 700,
              textTransform: 'none'
            }}
            variant="subtitle1"
          >
            Set a due date?
          </Typography>
        </Stack>
      }
    >
      <Stack
        spacing={3}
        sx={
          isMobile
            ? {
                alignItems: 'center',
                maxWidth: 386,
                mt: 5,
                mx: 'auto',
                width: '100%'
              }
            : { alignItems: 'center', width: 386 }
        }
      >
        <Typography variant="body2">
          Setting deadlines is helpful for some, but not all. If it’s not your
          style, skip this step and your goal will appear as ongoing with no due
          date.
        </Typography>
        <DueDate goal={goal} setGoal={setGoal} />
        <FormControl disabled={isNil(prop('due_date', goal))} required>
          <FormControlLabel
            control={
              <Checkbox
                checked={consent}
                color="default"
                onChange={() => setConsent(not(consent))}
                sx={{ color: 'primary.tonal' }}
              />
            }
            label="Remind me about this goal"
          />
        </FormControl>
        <Button disabled={or(isLoading, isFetching)} onClick={handleSubmit}>
          Finish
        </Button>
        {isMobile && (
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
        )}
      </Stack>
    </AccessibleModal>
  )
}

export default PresetGoalModal

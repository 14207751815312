/* eslint-disable sort-keys */
import lightPalette from './lightPalette'
import { typography as MuiTypography } from './typography'

import { MuiAccordion as Accordion } from './components/MuiAccordion'
import { MuiAccordionSummary as AccordionSummary } from './components/MuiAccordionSummary'
import { MuiAlert as Alert } from './components/MuiAlert'
import { MuiAlertTitle as AlertTitle } from './components/MuiAlertTitle'
import { MuiAutocomplete as Autocomplete } from './components/MuiAutocomplete'
import { MuiAvatar as Avatar } from './components/MuiAvatar'
import { MuiBadge as Badge } from './components/MuiBadge'
import { MuiButton as Button } from './components/MuiButton'
import { MuiCard as Card } from './components/MuiCard'
import { MuiCardActions as CardActions } from './components/MuiCardActions'
import { MuiCardContent as CardContent } from './components/MuiCardContent'
import { MuiCheckbox as Checkbox } from './components/MuiCheckbox'
import { MuiChip as Chip } from './components/MuiChip'
import { MuiContainer as Container } from './components/MuiContainer'
import { MuiCssBaseline as CssBaseline } from './components/MuiCssBaseline'
import { MuiDayCalendar as DayCalendar } from './components/MuiDayCalendar'
import { MuiDialogTitle as DialogTitle } from './components/MuiDialogTitle'
import { MuiDivider as Divider } from './components/MuiDivider'
import { MuiDrawer as Drawer } from './components/MuiDrawer'
import { MuiFormLabel as FormLabel } from './components/MuiFormLabel'
import { MuiFormControlLabel as FormControlLabel } from './components/MuiFormControlLabel'
import { MuiIconButton as IconButton } from './components/MuiIconButton'
import { MuiInputAdornment as InputAdornment } from './components/MuiInputAdornment'
import { MuiInputBase as InputBase } from './components/MuiInputBase'
import { MuiInputLabel as InputLabel } from './components/MuiInputLabel'
import { MuiLinearProgress as LinearProgress } from './components/MuiLinearProgress'
import { MuiMenu as Menu } from './components/MuiMenu'
import { MuiMenuItem as MenuItem } from './components/MuiMenuItem'
import { MuiPagination as Pagination } from './components/MuiPagination'
import { MuiPaginationItem as PaginationItem } from './components/MuiPaginationItem'
import { MuiPaper as Paper } from './components/MuiPaper'
import { MuiPickersDay as PickersDay } from './components/MuiPickersDay'
import { MuiPickersArrowSwitcher as PickersArrowSwitcher } from './components/MuiPickersArrowSwitcher'
import { MuiPickersCalendarHeader as PickersCalendarHeader } from './components/MuiPickersCalendarHeader'
import { MuiOutlinedInput as OutlinedInput } from './components/MuiOutlinedInput'
import { MuiSelect as Select } from './components/MuiSelect'
import { MuiSkeleton as Skeleton } from './components/MuiSkeleton'
import { MuiSwitch as Switch } from './components/MuiSwitch'
import { MuiTab as Tab } from './components/MuiTab'
import { MuiTabs as Tabs } from './components/MuiTabs'
import { MuiTooltip as ToolTip } from './components/MuiTooltip'
import MuiUseMediaQuery from './components/MuiUseMediaQuery'

const lightTheme = {
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 700,
      laptop: 1024,
      desktop: 1200,
      monitor: 1400
    }
  },
  components: {
    MuiAlert: Alert(lightPalette),
    MuiAlertTitle: AlertTitle(lightPalette),
    MuiAccordion: Accordion(lightPalette),
    MuiAccordionSummary: AccordionSummary(lightPalette),
    MuiAutocomplete: Autocomplete(lightPalette),
    MuiAvatar: Avatar(lightPalette),
    MuiBadge: Badge(lightPalette),
    MuiButton: Button(lightPalette),
    MuiCard: Card(lightPalette),
    MuiCardActions: CardActions(lightPalette),
    MuiCardContent: CardContent(lightPalette),
    MuiCheckbox: Checkbox(lightPalette),
    MuiChip: Chip(lightPalette),
    MuiContainer: Container(lightPalette),
    MuiCssBaseline: CssBaseline(lightPalette),
    MuiDayCalendar: DayCalendar(lightPalette),
    MuiDialogTitle: DialogTitle(lightPalette),
    MuiDivider: Divider(lightPalette),
    MuiDrawer: Drawer(lightPalette),
    MuiFormControlLabel: FormControlLabel(lightPalette),
    MuiFormLabel: FormLabel(lightPalette),
    MuiIconButton: IconButton(lightPalette),
    MuiInputAdornment: InputAdornment(lightPalette),
    MuiInputBase: InputBase(lightPalette),
    MuiInputLabel: InputLabel(lightPalette),
    MuiLinearProgress: LinearProgress(lightPalette),
    MuiMenu: Menu(lightPalette),
    MuiMenuItem: MenuItem(lightPalette),
    MuiPagination: Pagination(lightPalette),
    MuiPaginationItem: PaginationItem(lightPalette),
    MuiPaper: Paper(lightPalette),
    MuiPickersDay: PickersDay(lightPalette),
    MuiPickersArrowSwitcher: PickersArrowSwitcher(lightPalette),
    MuiPickersCalendarHeader: PickersCalendarHeader(lightPalette),
    MuiOutlinedInput: OutlinedInput(lightPalette),
    MuiSelect: Select(lightPalette),
    MuiSkeleton: Skeleton(lightPalette),
    MuiSwitch: Switch(lightPalette),
    MuiTab: Tab(lightPalette),
    MuiTabs: Tabs(lightPalette),
    MuiTooltip: ToolTip(lightPalette),
    MuiUseMediaQuery
  },
  palette: lightPalette,
  spacing: 8,
  typography: MuiTypography(lightPalette)
}

export default lightTheme

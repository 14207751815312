const darkPalette = {
  brand: {
    bedFrame: '#272521',
    cheeto: '#F88621',
    cleanSocks: '#FAF4EA',
    electricLavendar: '#C1A5E5',
    iceBlue: '#8BD3DF',
    monsterra: '#00886C',
    puke: '#BFB63B',
    rage: '#F5432A'
  },
  button: {
    outlined: {
      background: '#454E4F',
      disabled: '#626262',
      dragged: '#0F2428',
      focused: '#0D1F22',
      hover: '#091517',
      onSurface: '#8FC5CF',
      textDisabled: '#626262'
    },
    primary: {
      background: '#8FC5CF',
      disabled: 'rgba(86, 86, 86, 0.12)',
      dragged: '#97D7E1',
      focused: '#9DDCE7',
      hover: '#A4E3EE',
      onSurface: '#12353C',
      textDisabled: '#626262'
    },
    text: {
      background: '#E0EAEC',
      disabled: '#626262',
      dragged: '#566568',
      focused: '#515F62',
      hover: '#4D595B',
      onSurface: '#8FC5CF',
      textDisabled: '#626262'
    },
    tonal: {
      background: '#454D4F',
      disabled: 'rgba(86, 86, 86, 0.12)',
      dragged: '#566568',
      focused: '#515F62',
      hover: '#4D595B',
      onSurface: '#BFE2E9',
      textDisabled: '#626262'
    }
  },
  card: {
    background: '#272521',
    dragged: '#312F2B',
    focused: '#2F2C28',
    hover: '#2C2A26'
  },
  error: {
    hover: '#FDAC9E1F',
    main: '#B11D13',
    onSurface: '#FAF4EA'
  },
  primary: {
    container: '#1F4E57',
    main: '#EEE7DB',
    onPrimary: '#12353C',
    swatch: '#8FC5CF',
    tonal: '#C4CED0',
    tonalContainer: '#454D4F'
  },
  secondary: {
    container: '#4C2E77',
    main: '#EEE7DB',
    onSecondary: '#371A60',
    swatch: '#D1B1FA',
    tonal: '#4C2E77',
    tonalContainer: '#5A3D82'
  },
  surface: {
    base: '#181612',
    container: '#272521',
    high: '#302D29',
    highest: '#3C3934',
    low: '#211F1C',
    lowest: '#13110D',
    onSurface: '#EEE7DB',
    onSurfaceVariant: '#D0CCC4',
    outline: '#7E7A74'
  }
}

export default darkPalette

import { Checkbox, Stack, TextField } from '@mui/material'
import { useUpdateGoalMutation } from 'api/goalsApi'
import PropTypes from 'prop-types'
import {
  and,
  assoc,
  compose,
  equals,
  findIndex,
  insert,
  isNil,
  lt,
  not,
  or,
  path,
  prop,
  propEq,
  set,
  sortBy,
  without
} from 'ramda'
import { useSelector } from 'react-redux'

const Step = ({ goal, index, setGoal, setIsChanged, step }) => {
  const { goalModalId } = useSelector(prop('goal'))
  const isEdit = not(isNil(goalModalId))
  const steps = prop('steps', goal)
  const isCompleted = prop('completed', step)

  const [updateGoal] = useUpdateGoalMutation()

  const handleCheck = () => {
    setIsChanged(true)
    // Create copy
    // find index of step to update
    // update copy with new updated step
    // on edit update goal
    // on create check box
    const copy = [...prop('steps', goal)]
    const foundIndex = findIndex(propEq(prop('id', step), 'id'))(
      prop('steps', goal)
    )
    const updatedStep = assoc('completed', not(isCompleted), step)
    copy[foundIndex] = updatedStep
    const body = assoc('steps', sortBy(prop('order'))(copy), goal)
    setGoal(body)
  }

  const handleChange = e => {
    setIsChanged(true)
    if (equals('\n', e.target.value)) {
      e.preventDefault()
      return
    }
    const updated = assoc('name', path(['target', 'value'], e), step)
    const stepsCopy = [...steps]
    stepsCopy[index] = updated
    setGoal(assoc('steps', [...stepsCopy], goal))
  }

  const handleEnter = e => {
    e.preventDefault()
  }

  const handleKeyUp = async e => {
    const { target } = e
    // Enter key creates new step
    // This one is for if current step is last on the list
    // need to account for inner steps and enter key
    if (equals('Enter', e.key)) {
      handleEnter(e)
      if (lt(target.textLength, 1)) return
      compose(
        () => handleEnter(e),
        setGoal,
        assoc('steps'),
        insert(index + 1, {
          completed: false,
          id: null,
          name: ''
        }),
        prop('steps')
      )(goal)
      // Wait for new step to be created, then focus on it
      return setTimeout(() => {
        document.getElementById(`step-${index + 1}-input`).focus()
      }, 0)
    }
    // delete at first char deletes step
    if (
      and(equals('Backspace', e.key), equals(0, prop('textLength', target)))
    ) {
      if (or(equals(0, index), equals(1, steps.length))) return
      // Remove step and set goal with existing steps
      const newSteps = without([step], prop('steps', goal))
      setGoal(assoc('steps', newSteps, goal))
      return document.getElementById(`step-${index - 1}-input`).focus()
    }
  }

  return (
    <Stack alignItems="flex-start" direction="row" spacing={1.5}>
      <Checkbox
        checked={isCompleted}
        color="default"
        onChange={handleCheck}
        sx={{ color: 'primary.tonal' }}
      />
      <TextField
        fullWidth
        id={`step-${index}-input`}
        multiline
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        placeholder={
          and(not(isEdit), equals(0, index))
            ? 'Remember that effective goals include smaller, actionable steps. Write them out here. \nNew step? New line.'
            : ''
        }
        slotProps={{
          input: {
            sx: {
              '& > textarea': {
                backgroundColor: 'inherit',
                borderRadius: 'inherit',
                fontSize: 'inherit',
                lineHeight: 'inherit',
                px: 0.5,
                py: 0.75
              },
              backgroundColor: 'transparent',
              borderRadius: 0,
              fontSize: 14,
              lineHeight: 1.4,
              mt: 0.5,
              p: 0
            }
          }
        }}
        value={prop('name', step)}
      />
    </Stack>
  )
}

Step.propTypes = {
  goal: PropTypes.shape({}),
  index: PropTypes.number,
  setGoal: PropTypes.func,
  setIsChanged: PropTypes.func,
  step: PropTypes.shape({
    completed: PropTypes.bool,
    id: PropTypes.number,
    name: PropTypes.string
  })
}

export default Step

import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import Step from './Step'

const Steps = ({ goal, setGoal, setIsChanged }) => {
  const steps = prop('steps', goal)
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      pl={isMobile ? 0 : 3}
      spacing={2}
      sx={{ justifyContent: 'center', width: 1 }}
    >
      <Typography
        sx={
          isMobile
            ? {
                pl: 1,
                pt: 2,
                whiteSpace: 'nowrap'
              }
            : {
                pt: 2,
                whiteSpace: 'nowrap'
              }
        }
      >
        Steps*
      </Typography>
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'surface.outline',
          borderRadius: 4,
          maxWidth: 460,
          px: 2.5,
          py: 2,
          width: 1
        }}
      >
        {steps.map((step, idx) => (
          <Step
            goal={goal}
            index={idx}
            key={idx}
            setGoal={setGoal}
            setIsChanged={setIsChanged}
            step={step}
          />
        ))}
      </Box>
    </Stack>
  )
}

Steps.propTypes = {
  goal: PropTypes.shape({}).isRequired,
  setGoal: PropTypes.func.isRequired,
  setIsChanged: PropTypes.func.isRequired
}

export default Steps

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import {
  and,
  any,
  assoc,
  equals,
  isEmpty,
  isNil,
  not,
  or,
  prop,
  reject,
  set,
  sortBy
} from 'ramda'
import { resetState, setImg, setIsConfirmationOpen } from '../goalSlice'
import { useEffect, useState } from 'react'
import AccessibleModal from 'components/modals/AccessibleModal'
import {
  useCreateGoalMutation,
  useDeleteGoalMutation,
  useGetGoalQuery,
  useUpdateGoalMutation
} from 'api/goalsApi'
import Name from './Name'
import DueDate from './DueDate'
import Category from './Category'
import Steps from './Steps'
import Icon from 'components/icon/Icon'

const GoalModal = () => {
  const dispatch = useDispatch()
  const { goalModalId, isGoalModalOpen } = useSelector(prop('goal'))

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('laptop'))

  const emptyGoal = {
    campus_goal_id: null,
    card_id: null,
    domain_id: null,
    due_date: null,
    img: '/vectors/completed-goal.svg',
    steps: [
      {
        completed: false,
        id: null,
        name: ''
      }
    ],
    subdomain_id: null,
    title: ''
  }

  const handleClose = () => {
    setGoal(emptyGoal)
    setIsChanged(false)
    dispatch(resetState())
  }
  const isEdit = not(isNil(goalModalId))

  const { data = {}, isFetching } = useGetGoalQuery(goalModalId, {
    skip: isNil(goalModalId)
  })

  const [createGoal, { isLoading }] = useCreateGoalMutation()
  const [deleteGoal] = useDeleteGoalMutation()
  const [updateGoal] = useUpdateGoalMutation()

  const [goal, setGoal] = useState(emptyGoal)
  const [isChanged, setIsChanged] = useState(false)

  const isMissingRequiredFields = () => {
    return or(
      or(isEmpty(prop('title', goal)), isEmpty(prop('category_id', goal))),
      and(
        equals(1, prop('steps', goal).length),
        isEmpty(prop('steps', goal)[0].name)
      )
    )
  }

  useEffect(() => {
    if (and(not(isNil(goalModalId)), not(isEmpty(data)))) {
      const stepsCopy = [...data.steps]
      const orderedData = assoc('steps', sortBy(prop('order'))(stepsCopy), data)
      setGoal(orderedData)
    }
  }, [data, goalModalId])

  useEffect(() => {
    if (isNil(goalModalId)) {
      dispatch(setImg('/vectors/completed-goal.svg'))
    }
  }, [dispatch, goalModalId])

  const { due_date } = goal

  useEffect(() => {
    // If due date is cleared then clear the reminder checkbox
    if (isNil(due_date)) {
      setGoal(assoc('notified_on_due_date', false, goal))
    }
  }, [due_date])

  const handleSubmit = async () => {
    try {
      const body = new FormData()
      const transformedGoal = reject(isNil, goal)
      for (const [key, value] of Object.entries(transformedGoal)) {
        if (equals(key, 'steps')) {
          goal.steps.forEach((step, index) => {
            body.append('steps[' + index + '][name]', step.name)
          })
        } else if (equals(key, 'due_date')) {
          body.append(key, new Date(value).toISOString())
        } else {
          body.append(key, value)
        }
      }
      if (not(isEdit)) {
        const newGoalResponse = await createGoal(body)
        handleClose()
        // Wait until close to set confirmation modal open
        setTimeout(() => {
          dispatch(setIsConfirmationOpen(true))
        }, 0)
        // Set goal id in local storage for confirmationM modal to use
        localStorage.setItem(
          'confirmation_modal_goal_id',
          prop('id', prop('data', newGoalResponse))
        )
      } else {
        const { id } = goal
        const putBody = Object.fromEntries(body)
        putBody.steps = goal.steps
        await updateGoal({ body: putBody, id: id })
        handleClose()
      }
    } catch (err) {
      console.warn('Error creating new goal:', err)
    }
  }

  return (
    <AccessibleModal
      fullScreenMobile={true}
      onClose={handleClose}
      open={isGoalModalOpen}
      title={
        <Stack alignItems="center" spacing={2}>
          <Box
            sx={{
              backgroundImage: 'url(/vectors/you-logo.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 24,
              width: 26
            }}
          />
          <Typography
            sx={{
              textTransform: 'none'
            }}
            textAlign="center"
            variant="h4"
          >
            {isEdit ? 'Edit Goal' : 'Create a Goal'}
          </Typography>
          <Typography
            sx={{
              textTransform: 'none'
            }}
            variant="body2"
          >
            {isEdit
              ? 'Your recipe for success. Effective goals include smaller, actionable steps.'
              : `Dream big and think about what you’re striving for right now.`}
          </Typography>
        </Stack>
      }
    >
      <Stack
        component="form"
        spacing={isMobile ? 2 : 4}
        sx={
          isMobile
            ? {
                alignItems: 'center',
                maxWidth: 460,
                mt: 5,
                mx: 'auto',
                width: '100%'
              }
            : { alignItems: 'center', mt: 5, width: 598 }
        }
      >
        <Name goal={goal} setGoal={setGoal} setIsChanged={setIsChanged} />
        <Category goal={goal} isEdit={isEdit} setGoal={setGoal} />
        <Steps goal={goal} setGoal={setGoal} setIsChanged={setIsChanged} />
        <DueDate
          goal={goal}
          isCustom={true}
          isEdit={isEdit}
          setGoal={setGoal}
          setIsChanged={setIsChanged}
        />
        <FormControl disabled={isNil(prop('due_date', goal))} required>
          <FormControlLabel
            control={
              <Checkbox
                checked={prop('notified_on_due_date', goal)}
                color="default"
                onChange={value => {
                  setGoal(assoc('notified_on_due_date', value.target.checked))
                  setIsChanged(true)
                }}
                sx={{ color: 'primary.tonal' }}
              />
            }
            label="Remind me about this goal"
          />
        </FormControl>
        <Button
          disabled={any(equals(true), [
            isLoading,
            isMissingRequiredFields(),
            and(isEdit, not(isChanged))
          ])}
          onClick={handleSubmit}
        >
          Save Goal
        </Button>
        {isEdit && (
          <Button
            error
            onClick={() => {
              deleteGoal(prop('id', goal))
              handleClose()
            }}
            startIcon={<Icon>delete</Icon>}
            variant="text"
          >
            <FormattedMessage defaultMessage="Delete this goal" id="Vyw42+" />
          </Button>
        )}
        {isMobile && (
          <Button onClick={handleClose} variant="text">
            Cancel
          </Button>
        )}
      </Stack>
    </AccessibleModal>
  )
}

export default GoalModal

import { createSlice } from '@reduxjs/toolkit'
import { assoc } from 'ramda'

const initialState = {
  goalModalId: null,
  img: '/vectors/completed-goal.svg',
  inProgressGoalCount: 0,
  isCompletionModalOpen: false,
  isConfirmationOpen: false,
  isGoalModalOpen: false,
  isPresetGoalModalOpen: false,
  presetGoalModalId: null,
}

const goalSlice = createSlice({
  initialState,
  name: 'goal',
  reducers: {
    resetState: () => {
      return initialState
    },
    setGoalModalId: (state, { payload }) =>
      assoc('goalModalId', payload, state),
    setImg: (state, { payload }) => assoc('img', payload, state),
    setInProgressGoalCount: (state, { payload }) =>
      assoc('inProgressGoalCount', payload, state),
    setIsCompletionModalOpen: (state, { payload }) =>
      assoc('isCompletionModalOpen', payload, state),
    setIsConfirmationOpen: (state, { payload }) =>
      assoc('isConfirmationOpen', payload, state),
    setIsGoalModalOpen: (state, { payload }) =>
      assoc('isGoalModalOpen', payload, state),
    setIsPresetGoalModalOpen: (state, { payload }) =>
      assoc('isPresetGoalModalOpen', payload, state),
    setPresetGoalModalId: (state, { payload }) =>
      assoc('presetGoalModalId', payload, state),
  }
})

export const {
  resetState,
  setGoalModalId,
  setImg,
  setInProgressGoalCount,
  setIsConfirmationOpen,
  setIsCompletionModalOpen,
  setIsGoalModalOpen,
  setIsPresetGoalModalOpen,
  setPresetGoalModalId
} = goalSlice.actions

export default goalSlice.reducer

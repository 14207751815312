import { Box, Button, CardMedia, Stack, Typography } from '@mui/material'
import { prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { resetState, setGoalModalId, setIsGoalModalOpen } from '../goalSlice'
import AccessibleModal from 'components/modals/AccessibleModal'

const GoalConfirmationModal = () => {
  const dispatch = useDispatch()
  const { img, isConfirmationOpen } = useSelector(prop('goal'))

  const handleClose = () => dispatch(resetState())

  return (
    <AccessibleModal
      fullScreenMobile={true}
      onClose={handleClose}
      open={isConfirmationOpen}
      title={
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            maxWidth: 386
          }}
        >
          <Box
            sx={{
              backgroundImage: 'url(/vectors/you-logo.svg)',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              height: 24,
              width: 26
            }}
          />
          <Typography
            sx={{
              fontWeight: 700,
              textTransform: 'none'
            }}
            textAlign="center"
            variant="h4"
          >
            Goal Set!
          </Typography>
          <Typography
            sx={{
              textTransform: 'none'
            }}
            variant="subtitle1"
          >
            You can check on your upcoming goals from the Homepage or the Goals
            page at anytime.
          </Typography>
        </Stack>
      }
    >
      <Stack alignItems="center" spacing={3}>
        <CardMedia
          alt=""
          component="img"
          src={img}
          sx={{
            bottom: 0,
            height: 184,
            width: 286,
            zIndex: 0
          }}
        />
        <Button onClick={handleClose}>Got It, Thanks!</Button>
        <Button
          onClick={
            // Open goal modal with goal id from local storage
            () => {
              handleClose()
              dispatch(
                setGoalModalId(
                  localStorage.getItem('confirmation_modal_goal_id')
                )
              )
              // wait for modal to close before opening goal modal
              setTimeout(() => {
                dispatch(setIsGoalModalOpen(true))
              }, 0)
            }
          }
          variant="text"
        >
          Edit This Goal
        </Button>
      </Stack>
    </AccessibleModal>
  )
}

export default GoalConfirmationModal

import { Button, CardMedia, Stack, Typography } from '@mui/material'
import { gt, gte, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { resetState } from '../goalSlice'
import AccessibleModal from 'components/modals/AccessibleModal'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Icon from 'components/icon/Icon'

const GoalCompletionModal = () => {
  const dispatch = useDispatch()
  const { img, isCompletionModalOpen, inProgressGoalCount } = useSelector(
    prop('goal')
  )

  const handleClose = () => dispatch(resetState())

  const stackStyles = {
    alignItems: 'center',
    justifyContent: 'center'
  }

  return (
    <AccessibleModal
      fullScreenMobile={true}
      onClose={handleClose}
      open={isCompletionModalOpen}
      title={
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            maxWidth: 526,
            px: '24px'
          }}
        >
          <Icon fill={true}>mountain_flag</Icon>
          <Typography sx={{ textTransform: 'none' }} variant="h1">
            <FormattedMessage defaultMessage="Well done!" id="lJKfqg" />
          </Typography>
          <Typography sx={{ textTransform: 'none' }} variant="h2">
            You just completed your goal!
          </Typography>
          <CardMedia
            alt=""
            component="img"
            src={img}
            sx={{
              bottom: 0,
              height: 184,
              width: 286,
              zIndex: 0
            }}
          />
        </Stack>
      }
    >
      <Stack spacing={4} sx={{ ...stackStyles }}>
        {gt(inProgressGoalCount, 0) && (
          <>
            <Stack>
              <Typography variant="body1">
                Your hard work paid off! Now, on to the next one.
              </Typography>
              <Typography component="span" variant="body1">
                Looks like you have{' '}
                <b>
                  {inProgressGoalCount}{' '}
                  {gte(inProgressGoalCount, 2) ? 'goals' : 'goal'} in progress
                </b>
              </Typography>
            </Stack>
            <Button
              LinkComponent={Link}
              onClick={handleClose}
              to="/goals?active=my-goals"
            >
              Check out in progress goals
            </Button>
          </>
        )}
        <Button
          LinkComponent={Link}
          onClick={handleClose}
          to="/goals?active=explore"
          variant="outlined"
        >
          Set a new goal
        </Button>
      </Stack>
    </AccessibleModal>
  )
}

export default GoalCompletionModal

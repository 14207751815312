import { Button, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { setIsGoalModalOpen } from '../goalSlice'
import Icon from 'components/icon/Icon'

const EmptyState = () => {
  const dispatch = useDispatch()

  return (
    <Stack
      alignItems="center"
      spacing={2}
      // eslint-disable-next-line sort-keys
      sx={{ mt: { mobile: 4, tablet: 8 }, px: 2 }}
    >
      <Typography variant="subtitle1">
        You don’t currently have any active goals.
      </Typography>
      <Button
        endIcon={<Icon>arrow_forward</Icon>}
        onClick={() => dispatch(setIsGoalModalOpen(true))}
        variant="text"
      >
        create my own goal
      </Button>
    </Stack>
  )
}

export default EmptyState
